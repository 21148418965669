@tailwind base;
@tailwind components;
@tailwind utilities;

/* Example animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

h1 {
  animation: fadeIn 2s ease-in;
}

img {
  animation: fadeIn 2s ease-in;
}

body {
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  font-family: 'Arial', sans-serif;
}

h1, h2 {
  color: #333;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin-top: 1rem;
}

/* Hero Section Adjustments */
#home {
  min-height: 70vh; /* Adjust this value to reduce the height */
  padding-top: 2rem; /* Adjust padding as needed */
  padding-bottom: 2rem; /* Adjust padding as needed */
}

/* About Section Adjustments */
#about {
  padding-top: 2rem; /* Adjust padding as needed */
  padding-bottom: 2rem; /* Adjust padding as needed */
}
